.modalmain{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 10;
   background-color: hsl(217, 24%, 17%,0.2);
}
.modalcon{
    width: 40%;
    background-color: red;
    display: flex;
  align-items: center;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
    background-color: #212936;
    border: 1px solid hsl(82, 72%, 62%,0.4);
}
.close{
    width: 100%;
    display: flex;
    margin: 10px;
    justify-content: flex-end;
}
.close>button{
    background-color: transparent;
    color: lightgray;
    border: none;
    outline: none;
    font-size: 18px;
    cursor: pointer;
}
.title{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #B1E457;
    font-size: 20px;
    align-items: center;
}
@media only screen and (max-width: 600px) {
    .modalcon{
        width: 80%;
    }
}