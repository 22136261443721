
@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@700&family=Raleway:wght@300&display=swap');
.App{
  position: absolute;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.triangle1{
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-bottom: 530px solid transparent;
  border-left: 120px solid #B1E457; /* Change the color as per your preference */
  position: absolute;
  left: 0;
  z-index: 1;
  
}
.triangle2{
  width: 0;
  height: 0;
  border-top: 180px solid transparent;
  border-bottom: 580px solid transparent;
  border-left: 160px solid #8CA005; /* Change the color as per your preference */
  position: absolute;
  left: 0;
}
.triangle3{
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-bottom: 530px solid transparent;
  border-right: 120px solid #B1E457; /* Change the color as per your preference */
  position: absolute;
  right: 0;
  z-index: 1;
}
.triangle4{
  width: 0;
  height: 0;
  border-top: 180px solid transparent;
  border-bottom: 580px solid transparent;
  border-right: 160px solid #8CA005; /* Change the color as per your preference */
  position: absolute;
  right: 0;
}
.container{
  width: 30%;
  background-color: #212936;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
  border-radius: 10px;

}
.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 7px;
  padding: 15px 0px;
}

.text{
 text-align: center;

 font-size: 25px;
 font-weight: bold;
 
font-family: 'Raleway', sans-serif;
 color: #B1E457;
 

}
.underline{
  width: 15%;
  height: 3px;
  background-color: #B1E457;
  border-radius: 2px;

}
.toggle{
  display: flex;
  flex-direction: row;
  justify-content:center;
  padding: 5px 0px;
}
.btn{
  width: 46%;
  height: 27px;
  outline: none;
  background-color: #c4c4c443;
  border: none;
  cursor: pointer;
  
font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: white;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
 
}
.btn:hover{
  opacity: 0.8;
}
.btn1{
  
  border-radius: 3px 0px 0px 3px;
}
.btn2{
 
  border-radius: 0px 3px 3px 0px;
}
.active{
  background-color: #B1E457;
  transition: ease 0.8s;
  color: black;
}
.inputfld, .pswdin{
  width: 80%;
  height: 33px;
  background: rgba(39, 49, 65, 1  );
  outline: none;
  border: none;
  
font-family: 'Raleway', sans-serif;
  font-weight: bold;
  border: 1px solid hsl(82, 72%, 62%,0);
  border-bottom: 1px solid #B1E457;
  color: white;
  font-size: 15px;
  padding-left: 5PX;
  transition: background 0.3s ease; 
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
}
.pswdfld{
  width: 90%;
  height: 100%;
  outline: none;
  background-color: transparent;
color: white;
  border: none;
}
.inputfld:hover,.pswdin:hover {
  background: rgb(36, 42, 56);
}
.inputfld:focus,.pswdin:focus{
  border: 1px solid hsl(82, 72%, 62%,0.2);
  border-bottom: 1px solid #B1E457;
}
.input{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
}
.inputp{
  width: 90%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  
}
.inputs{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px 3px 0px;
 
}
.img{
  opacity: 0.9;
}
::placeholder {
  color: rgb(142, 140, 140);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { 
  color: rgb(142, 140, 140);
}
.submitbtn{
  background-color: #B1E457;
  border-radius: 3px;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
width: 40%;
height: 35px;

font-family: 'Raleway', sans-serif;
  font-weight: bold;
  cursor: pointer;
font-size: 16px;
margin: 0px 0px 5px 0px;
color: black;
}
.submitbtn:hover{
  transform: scale(1.01);
  transition: ease 0.2s;
  opacity: 0.9;
}
.or{
  margin: 5px 0;
  width: 100%;
  align-items: center;
  display: flex;
  color: white;
  justify-content: center;
  opacity: 0.6;
  font-size: 13px;
}
.eye{
color: white;
}
.strength-password {
  position: relative;
  width: 0%;
  height: 3px;
  top: 9px;
  left: 0;
  background: transparent;
  transition: all 300ms ease-in-out;
}

.strength-password[data-score="null"] {
  width: 0;
  background: red;
}

.strength-password[data-score="0"] {
  width: 10%;
  background: #f44336;
}

.strength-password[data-score="1"] {
  width: 40%;
  background: #ffeb3b;
}

.strength-password[data-score="2"] {
  width: 75%;
  background: #4caf50;
}

.strength-password[data-score="3"] {
  width: 100%;
  background: #4caf50;
}

.strength-password[data-score="4"] {
  width: 100%;
  background: #4caf50;
}
.p{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
.dis{
  background-color: #546d2a;
  cursor:not-allowed;
  transition: ease 1s;
  transform: scale(1) !important;
}

.toast-message {
  background-color: #212936 !important;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6) !important; 
  color: rgba(255, 255, 255, 0.654) !important;
  font-size: 13px;
  
}
.info{
  position: absolute;
  bottom: 10px;
  right: 3.5%;
  color: #546d2a;
  z-index: 1;
  cursor: pointer;
}
.tooltip{
  z-index: 10;
  font-size: 10px !important;
  opacity: 0.7;
  color: lightgray !important ;
  padding: 5px !important;
}
.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.askphn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.usephn{
  background-color: transparent;
  margin: 0;
  color: #B1E457;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px 10px;
  font-size: 12px;
opacity: 0.9;
}
.otp{
  margin: 5px;
}
.otp>input{
  background-color: rgba(39, 49, 65, 1  );
  outline: none;
  border: 1px solid hsl(82, 72%, 62%,0.5);
  color: white;
  font-size: 20px;
  margin: 0px 5px !important;
  
}
.otp>input:first-child{
  margin-left: 0 !important;
}
.otptext{
  color: white;
  margin: 5px 0px 0px;
}
.phninput>input{
  background-color: rgba(39, 49, 65, 1  ) !important;
  border: none !important;
  border-bottom: 1px solid #B1E457 !important;
  outline: none !important;
  border-radius:0 !important ;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6) !important; 
  color: white;
  font-size: 15px !important;
  transition: background 0.3s ease; 
  
}
.phninput>input:hover {
  background: rgb(36, 42, 56) !important;
}
.phninput>input:focus{
  border: 1px solid hsl(82, 72%, 62%,0.2) !important;
  border-bottom: 1px solid #B1E457 !important;
}
.country-list{
  background: rgb(36, 42, 56) !important;
  color: white !important;
}
.country:hover{
  background: rgba(91, 121, 186, 0.3) !important;
}
.country-list .country.highlight{
  background-color: rgb(43, 64, 102) !important;
}
.dial-code{
  color: darkgray !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background: rgb(43, 51, 68) !important;
  border-radius: 0 !important; 
}
.react-tel-input .selected-flag .arrow {
  border-top: 4px solid white !important;
}
.flag-dropdown{
  background-color: rgba(39, 49, 65, 1  ) !important ;
  outline: none !important;
  border: none !important;
  border-bottom: 1px solid #B1E457 !important;
  border-radius:0 !important ;
}

.flag-dropdown.open .selected-flag{
  background: rgb(36, 42, 56) !important;
}
.flag-dropdown.open .selected-flag:hover{
  background: rgb(36, 42, 56) !important;
}
.splinpput{
  align-items: flex-start !important;
}
.splinpput>img{
  margin-top: 5px !important;
}
.sociallogin{
  display: flex;
  justify-content: space-evenly;
align-items: center;
  padding: 15px 0;
  margin: 10px 0;
}
.last{
  margin: 15px 0;
}
.socialimg{
  background-color: transparent;
width: 35px;
  cursor: pointer;
  transition: ease 0.2s;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6) !important; 
}
.socialimg:hover{
  opacity: 0.9;
  transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
  .triangle1,
  .triangle2,
  .triangle3,
  .triangle4 {
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
  }

  .triangle1 {
    border-width: 50px 440px 0 120px;
    border-color: #B1E457 transparent transparent transparent;
    left: 0%;
    top: 0;
   
  }

  .triangle2 {
    border-width:80px 440px 0 130px;
    border-color: #8CA005 transparent transparent transparent;
    left: 0%;
    top: 0px;
  }

  .triangle3 {
    border-width: 0px 440px 50px 120px;
    border-color: transparent  transparent #B1E457 transparent;
    left: 0%;
    bottom: 0;
  
  }

  .triangle4 {
    border-width:0px 440px 80px 130px;
    border-color:transparent  transparent #8CA005 transparent;
    left: 0%;
    bottom: 0;
  }

  .container {
    /* Adjustments for smaller screens */
    width: 90%;
  }
  .phninput>input {
    width: 100% !important;
  }

  .submitbtn{
    width: 55%;
  }

}