
@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@700&family=Raleway:wght@300&display=swap');
.dashboard{
  position: absolute;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
 align-items: center;
 flex-direction: column;
}
.dtriangle1{
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-bottom: 530px solid transparent;
  border-left: 120px solid #B1E457; /* Change the color as per your preference */
  position: absolute;
  left: 0;
  z-index: 1;
}
.dtriangle2{
  width: 0;
  height: 0;
  border-top: 180px solid transparent;
  border-bottom: 580px solid transparent;
  border-left: 160px solid #8CA005; /* Change the color as per your preference */
  position: absolute;
  left: 0;
}
.dtriangle3{
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-bottom: 530px solid transparent;
  border-right: 120px solid #B1E457; /* Change the color as per your preference */
  position: absolute;
  right: 0;
  z-index: 1;
}
.dtriangle4{
  width: 0;
  height: 0;
  border-top: 180px solid transparent;
  border-bottom: 580px solid transparent;
  border-right: 160px solid #8CA005; /* Change the color as per your preference */
  position: absolute;
  right: 0;
}
.con{
display: flex;
flex-direction: column;
align-items: center;
}
.dheader{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 7px;
  padding: 15px 0px;
  margin: 20px 0px 0px 0px;
}

.dtext{
 text-align: center;
 font-size: 35px;
 font-weight: bold;
 
font-family: 'Raleway', sans-serif;
 color: #B1E457;
 

}
.dunderline{
  width: 40%;
  height: 3px;
  background-color: #B1E457;
  border-radius: 2px;

}
.dicon{
  margin: 10%;
}
.dsubmitbtn{
  background-color: #B1E457;
  border-radius: 3px;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
width: 25%;
height: 35px;

font-family: 'Raleway', sans-serif;
  font-weight: bold;
  cursor: pointer;
font-size: 16px;
margin: 20px 0px;
color: black;
}
.dsubmitbtn:hover{
  transform: scale(1.01);
  transition: ease 0.2s;
}
.details{
  width: 100%;
  display: flex;
  align-items: center;
  color: #B1E457;
  font-size: 22px;
  margin: 10px 0px;
  
font-family: 'Raleway', sans-serif;
font-weight: bold;
text-align: center;
}
.spclfont>span{
  font-family:'Courier New', Courier, monospace !important;
}
.details>span{
  color: white;
  font-weight: normal;
}
.dsendbtn{
  background-color: #B1E457;
  border-radius: 3px;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
width: 25%;
height: 35px;
text-align: center;

font-family: 'Raleway', sans-serif;
  font-weight: bold;
  cursor: pointer;
font-size: 16px;
margin: 20px 0px;
color: black;
}
.dsendbtn:hover{
  transform: scale(1.01);
  transition: ease 0.2s;
}
.buttoncon{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.btncon{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  align-items: center;
}
.dropdown{
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6); 
width: 25%;
height: 35px;

font-family: 'Raleway', sans-serif;
  font-weight: bold !important;
  cursor: pointer;
font-size: 16px;
margin: 20px 0px;
color: black;
}
.Dropdown-control{
  background-color: #B1E457 !important;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.6) !important; 
  outline: none !important;
  border: none !important;
  height: 35px !important;
  cursor: pointer !important;
}
.Dropdown-control:hover{
  transform: scale(1.01) !important;
  transition: ease 0.2s !important;
}
.Dropdown-option{
background-color: rgb(36, 42, 56) !important;
border: none !important;
border-bottom: 1px solid #B1E457 !important ;
outline: none !important;
color: lightgray !important;
}
.Dropdown-option:hover{
  background-color: rgb(1, 2, 7) !important;
}
.Dropdown-menu{
  border: 0 !important;
 
}
.Dropdown-arrow{
  border-color:black transparent transparent !important;
}
.is-open .Dropdown-arrow{
  border-color:transparent transparent black  !important;
}
@media only screen and (max-width: 600px) {
  .dtriangle1,
  .dtriangle2,
  .dtriangle3,
  .dtriangle4 {
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
  }

  .dtriangle1 {
    border-width: 50px 440px 0 120px;
    border-color: #B1E457 transparent transparent transparent;
    left: 0%;
    top: 0;
   
  }

  .dtriangle2 {
    border-width:80px 440px 0 130px;
    border-color: #8CA005 transparent transparent transparent;
    left: 0%;
    top: 0px;
  }

  .dtriangle3 {
    border-width: 0px 440px 50px 120px;
    border-color: transparent  transparent #B1E457 transparent;
    left: 0%;
    bottom: 0;
  
  }

  .dtriangle4 {
    border-width:0px 440px 80px 130px;
    border-color:transparent  transparent #8CA005 transparent;
    left: 0%;
    bottom: 0;
  }
  .con{
    width: 80%;
  }
  .btncon{
    width: 95%;
  }
  .dropdown {
    width: 35%;
    font-size: 14px;

  }
  .dsendbtn{
    width: 35%;
    font-size: 14px;
  }
  .dsubmitbtn{
    width: 35%;
    font-size: 15px;
  }
  .details{
    font-size: 18px;
  }
  .Dropdown-option{
    font-size: 14px;
  }
  .Dropdown-control{
    padding: 0px 10px !important;
  }
}